import { createSlice } from '@reduxjs/toolkit'

//Slice
export const errorsSlice = createSlice({
  name: 'errors',
  initialState: {
    postErrors: [],
    userErrors: []
  },
  reducers: {
    getPostErrors: (state, action) => {
      state.postErrors = action.payload;
    },
    getUserErrors: (state, action) => {
      state.userErrors = action.payload;
    }
  }
});

export default errorsSlice.reducer;

// Actions
const { getPostErrors, getUserErrors } = errorsSlice.actions;

export const fetchPostErrors = (errors) => async dispatch => {
  dispatch(getPostErrors(errors));
};

export const fetchUserErrors = (errors) => async dispatch => {
  dispatch(getUserErrors(errors));
};
