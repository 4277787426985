import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

import rootReducer from './rootReducer'
import './styles/index.scss'
import { fetchUsers } from './slices/usersSlice';
import { fetchAllPosts } from './slices/allPostsSlice';

if (process.env.NODE_ENV === 'production') disableReactDevTools();

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = configureStore ({
  reducer: {
    rootReducer
  },
  devTools: false
})

store.dispatch(fetchUsers());
store.dispatch(fetchAllPosts());

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);