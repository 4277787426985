import React, {useEffect, useState, useContext} from 'react';
import { useSelector } from "react-redux";
import { UidContext } from './AppContext';
import { isEmpty } from '../utils';
import Card from './Post/Card';
import Trends from './Trends';
import FriendsHint from './Profil/FriendsHint';
import LeftNav from './Nav/LeftNav';
import NewPostForm from './Post/NewPostForm';
import Log from './Log';

const FilteredThread = ({type, postId}) => {
    const uid = useContext(UidContext);
    const allPosts = useSelector(state => state.rootReducer.allPostsReducer.allPosts);
    const [filteredPosts, setFilteredPosts] = useState([]);

    useEffect(() => {
        if(type === "image"){
            setFilteredPosts(allPosts.filter(post => post.picture !== ""));
        }else if(type === "video"){
            setFilteredPosts(allPosts.filter(post => post.video !== ""));
        }else if(type === "article" ){
            setFilteredPosts(allPosts.filter(post => post.video === "" && post.picture === ""));
        }else if(type ==="id"){
            setFilteredPosts(allPosts.filter(post => post._id === postId));
        }
    }, [type, allPosts, postId]);

    return (
        <div className='filtered-thread'>
            <div className="wrapper">
                <Trends />
                {uid && <FriendsHint />}
            </div>
            <div className="main">
                <div className="thread-header">
                    {uid ? <NewPostForm /> : <Log signin={true} signup={false} />}
                </div>
                <ul>
                {!isEmpty(filteredPosts[0]) &&
                    filteredPosts.map((post) => {
                    return <Card post={post} key={post._id} />;
                    })}
                </ul>
            </div>
            <LeftNav />
      </div>
    );
};

export default FilteredThread;