import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { followUser, unFollowUser } from '../../slices/userSlice';
import { isEmpty } from '../../utils';

const FollowHandler = ({idToFollow, type}) => {

    const user = useSelector(state => state.rootReducer.userReducer.user);
    const [isFollowed, setIsFollowed] = useState(false);
    const dispatch = useDispatch();

    const handleFollow =() => {
        dispatch(followUser(user._id, idToFollow));
        setIsFollowed(true);
    }

    const handleUnFollow =() => {
        dispatch(unFollowUser(user._id, idToFollow));
        setIsFollowed(false);
    }

    useEffect(() => {
        if (!isEmpty(user.following)){
            if(user.following.includes(idToFollow)){
                setIsFollowed(true);
            }
        }
    }, [user, idToFollow])

    return (
        <>
            {isFollowed && !isEmpty(user) && (
            <span>
                {type==="suggestion" && <button onClick={handleUnFollow}>Abonné</button>}
                {type==="card" && <img onClick={handleUnFollow} src="/img/icons/checked.svg" alt="checked" />}
            </span>
            )}
            {isFollowed === false && !isEmpty(user) &&(
            <span>
                {type==="suggestion" && <button onClick={handleFollow}>Suivre</button>}
                {type==="card" && <img onClick={handleFollow} src="/img/icons/check.svg" alt="check" />}
            </span>
            )}
        </>
    );
};

export default FollowHandler;