import React from "react";
import { useDispatch } from "react-redux";
import { deletePost } from '../../slices/postsSlice';
import { deleteAllPost } from '../../slices/allPostsSlice';

const DeleteCard = (props) => {
  const dispatch = useDispatch();

  const deleteQuote = () => {
    dispatch(deletePost(props.id));
    dispatch(deleteAllPost(props.id));
  }

  return (
    <div
      onClick={() => {
        if (window.confirm("Voulez-vous supprimer cet article ?")) {
          deleteQuote();
        }
      }}
    >
      <img src="/img/icons/trash.svg" alt="trash" />
    </div>
  );
};

export default DeleteCard;
