import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrending } from "../slices/trendingSlice";
import { isEmpty } from "../utils";
import { NavLink } from "react-router-dom";

const Trends = () => {
  const allPosts = useSelector((state) => state.rootReducer.allPostsReducer.allPosts);
  const usersData = useSelector((state) => state.rootReducer.usersReducer.users);
  const trendList = useSelector((state) => state.rootReducer.trendingReducer.trending);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(allPosts[0])) {
      const postsArr = Object.keys(allPosts).map((i) => allPosts[i]);
      let sortedArray = postsArr.sort((a, b) => {
        return b.likers.length - a.likers.length;
      });
      sortedArray.length = 3;
      dispatch(fetchTrending(sortedArray));
    }
  }, [allPosts, dispatch]);

  return (
    <div className="trending-container">
      <h4>Tendances</h4>
        <ul>
          {trendList.length &&
            trendList.map((post) => {
              return (
                <NavLink exact="true" to={{
                  pathname:'/post/'+ post._id,
                }}>
                  <li key={post._id}>
                    <div>
                      {post.picture && <img src={post.picture} alt="post-pic" />}
                      {post.video && (
                        <iframe
                          src={post.video}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title={post._id}
                        ></iframe>
                      )}
                      {isEmpty(post.picture) && isEmpty(post.video) && (
                        <img src={usersData[0] && usersData.map((user) => {
                          if (user._id === post.posterId) {
                            return user.picture;
                          } else return null;
                        })
                        .join("")
                      } alt="profil-pic"/>
                      )}
                    </div>
                    <div className="trend-content">
                      <p>{post.message}</p>
                    </div>
                  </li>
                </NavLink>
              );
            })}
        </ul>
    </div>
  );
};

export default Trends;
