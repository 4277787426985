import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";

//Slice
export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: []
  },
  reducers: {
    getUsers: (state, action) => {
      state.users = action.payload;
    },
  }
});

export default usersSlice.reducer;

// Actions
const { getUsers} = usersSlice.actions;

export const fetchUsers = () => async dispatch => {
    await axios({
      method: 'get',
      url : process.env.REACT_APP_API_URL + "api/user",
      withCredentials: true,
    })
    .then((res) => dispatch(getUsers(res.data)))
    .catch((err) => console.log(err));
};
