import React, { useContext, useState }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import Log from "../Log";
import { UidContext } from "../AppContext";
import UploadImg from './UploadImg';
import { updateBio } from '../../slices/userSlice';
import { dateParser } from '../../utils';
import FollowHandler from './FollowHandler';

const UpdateProfil = () => {
    const uid = useContext(UidContext);
    const [bio, setBio] = useState('');
    const [updateForm, setUpdateForm] = useState(false);
    const [followingPopup, setfollowingPopup] = useState(false);
    const [followersPopup, setfollowersPopup] = useState(false);
    const user = useSelector(state => state.rootReducer.userReducer.user);
    const users = useSelector(state => state.rootReducer.usersReducer.users);
    const error = useSelector(state => state.rootReducer.errorsReducer.userErrors);
    const dispatch = useDispatch();

    const handleUpdate = () => {
        let bioData = {
            bio : bio
        }
        dispatch(updateBio(bioData, uid));
        setUpdateForm(false);
    }
    
    return (
        <div className='profil-container'>
            <div className="log-container">
                {uid ? <h1>Bienvenue sur votre profil {user.pseudo} !</h1> : <Log signin={true} signup={false} />}
            </div>
            {uid ? 
            <div className='update-container'>
                <div className='left-part'>
                    <h3>Photo de profil</h3>
                    <img src={user.picture +"?" + new Date().getTime()}  alt="user-pic" />
                    <UploadImg />
                    <p>{error.maxSize}</p>
                    <p>{error.format}</p>
                </div>
                <div className='right-part'>
                    <div className='bio-update'>
                        <h3>Bio</h3>
                        {updateForm === false && (
                            <>
                            <p onClick={() => {
                                setUpdateForm(!updateForm);
                                setBio(user.bio);
                            }}>{user.bio}</p>
                            <button onClick={() => setUpdateForm(!updateForm)}>Modifier bio</button>
                            </>
                        )}
                        {updateForm && (
                            <>
                            <textarea type="text" defaultValue={user.bio} onChange={(e) => setBio(e.target.value)}></textarea>
                            <button onClick={handleUpdate}>Valider modifications</button>
                            </>
                        )}    
                    </div>
                    <h4>Membre depuis le : {dateParser(user.createdAt)}</h4>
                    <h5 onClick={() => setfollowingPopup(true)}>Abonnements : {user.following ? user.following.length : "0"}</h5>
                    <h5 onClick={() => setfollowersPopup(true)}>Abonnés : {user.followers ? user.followers.length : "0"}</h5>
                </div>    
            </div> : <div> Connectez vous avant de pouvoir modifier votre profil !</div> }
            {followingPopup &&
                <div className='popup-profil-container'>
                    <div className="modal">
                        <h3>Abonnements</h3>
                        <span className="cross" onClick={() => setfollowingPopup(false)}>&#10005;</span>
                        <ul>
                            {users.map((userData) => {
                                let renvoyer;
                                for(let i= 0; i < user.following.length; i++){
                                    if(userData._id === user.following[i]){
                                        renvoyer = (
                                            <li key={userData._id}>
                                                <img src = {userData.picture} alt="user-pic" />
                                                <h4>{userData.pseudo}</h4>
                                                <FollowHandler idToFollow={userData._id} type={"suggestion"} />
                                            </li>
                                        )
                                    }
                                }
                                return renvoyer;
                            })}
                        </ul>
                    </div>
                </div>
            }
            {followersPopup &&
                <div className='popup-profil-container'>
                    <div className="modal">
                        <h3>Abonnés</h3>
                        <span className="cross" onClick={() => setfollowersPopup(false)}>&#10005;</span>
                        <ul>
                            {users.map((userData) => {
                                let renvoyer;
                                for(let i = 0; i < user.followers.length; i++){
                                    if(userData._id === user.followers[i]){
                                        renvoyer = (
                                            <li key={userData._id}>
                                                <img src = {userData.picture} alt="user-pic" />
                                                <h4>{userData.pseudo}</h4>
                                                <FollowHandler idToFollow={userData._id} type={"suggestion"} />
                                            </li>
                                        )
                                    }
                                }
                                return renvoyer;
                            })}
                        </ul>
                    </div>
                </div>
            }
        </div>
    );
};

export default UpdateProfil;