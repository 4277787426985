import React from 'react';
import { NavLink } from 'react-router-dom';

const LeftNav = () => {
    return (
        <div className='left-nav-container'>
            <div className='icons'>
                    <NavLink to='/' exact='true'>
                        <img src='/img/icons/home.svg' alt='home' />
                    </NavLink>
                    <br />
                    <NavLink to='/profil' exact='true'>
                        <img src='/img/icons/user.svg' alt='profil' />
                    </NavLink>
                    <br />
                    <NavLink to='/pictures' exact='true'>
                        <img src='/img/icons/picture.svg' alt='pictures' />
                    </NavLink>
                    <br />
                    <NavLink to='/videos' exact='true'>
                        <img src='/img/icons/video.svg' alt='videos' />
                    </NavLink>
                    <br />
                    <NavLink to='/articles' exact='true'>
                        <img src='/img/icons/article.svg' alt='articles' />
                    </NavLink>
            </div>
            
        </div>
    );
};

export default LeftNav;