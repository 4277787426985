import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "../Nav/Navbar";
import Home from "../../pages/Home";
import Profil from "../../pages/Profil";
import User from "../../pages/User";
import Pictures from "../../pages/Pictures";
import Videos from "../../pages/Videos";
import Articles from "../../pages/Articles";
import Post from "../../pages/Post";

const Routing = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/profil" exact element={<Profil />} />
        <Route path="/user/:id" exact element={<User />} />
        <Route path="/pictures" exact element={<Pictures />} />
        <Route path="/videos" exact element={<Videos />} />
        <Route path="/articles" exact element={<Articles />} />
        <Route path="/post/:id" exact element={<Post />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
};

export default Routing;
