import React, { useContext } from "react";
import Log from "../components/Log";
import { UidContext } from "../components/AppContext";
import UpdateProfil from "../components/Profil/UpdateProfil";
import LeftNav from "../components/Nav/LeftNav";
import Trends from "../components/Trends";
import FriendsHint from "../components/Profil/FriendsHint";

const Profil = () => {
  const uid = useContext(UidContext);

  return (
    <div className="profil-page">
      {uid ? (
        <>
          <div className="wrapper">
            <Trends />
            {uid && <FriendsHint />}
          </div>
          <UpdateProfil />
          <LeftNav />
        </>
      ) : (
          <div className="log-container">
            <Log signin={false} signup={true} />
            <div className="img-container">
              <img src="./img/log.jpg" alt="img-log" />
            </div>
          </div>
      )}
    </div>
  );
};

export default Profil;
