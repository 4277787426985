import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";

import Routing from "./components/Routing";
import { UidContext } from "./components/AppContext";
import { fetchUser } from "./slices/userSlice";

const App = () => {
  const [uid, setUid] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchToken = async () => {
      await axios({
        method: "get",
        url: process.env.REACT_APP_API_URL + "jwtid",
        withCredentials: true,
      })
        .then((res) => {
          setUid(res.data);
        })
        .catch((err) => console.log("No Token"));
    };
    fetchToken();
    if(uid) dispatch(fetchUser(uid));

  }, [uid, dispatch]);

  return (
    <UidContext.Provider value={uid}>
      <Routing />
    </UidContext.Provider>
  );
};

export default App;
