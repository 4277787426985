import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadPicture } from "../../slices/userSlice";

const UploadImg = () => {

    const [file, setFile] = useState();
    const dispatch = useDispatch();
    const user = useSelector(state => state.rootReducer.userReducer.user);

    const handlePicture = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("userId", user._id);
        data.append("name", user.pseudo);
        data.append("file", file);
        dispatch(uploadPicture(data, user._id));
    }
    
    return (
        <form action="" onSubmit={handlePicture} className='upload-pic'>
            <label htmlFor="file"> Changer l'image </label>
            {file ? <label className="file-path">{file.name}</label> : null}
            <input type="file" id="file" name="file" accept=".jpg, .jpeg, .png" onChange={(e) => setFile(e.target.files[0])}/>
            <input type="submit" value="Envoyer" />
        </form>
    );
};

export default UploadImg;