import React from 'react';
import { useParams } from 'react-router-dom';
import FilteredThread from '../components/FilteredThread';

const Post = () => {
    const { id } = useParams();
    return (
        <FilteredThread type="id" postId={id}/>
    );
};

export default Post;