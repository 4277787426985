import { combineReducers } from "@reduxjs/toolkit";

import postsSlice from "./slices/postsSlice";
import userSlice from './slices/userSlice';
import usersSlice from "./slices/usersSlice";
import allPostsSlice from "./slices/allPostsSlice";
import errorsSlice from "./slices/errorsSlice";
import trendingSlice from "./slices/trendingSlice";

export default combineReducers({
    userReducer : userSlice,
    usersReducer : usersSlice,
    postsReducer : postsSlice,
    errorsReducer : errorsSlice,
    allPostsReducer : allPostsSlice,
    trendingReducer : trendingSlice
});