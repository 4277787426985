import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { dateParser, isEmpty } from "../utils";
import Card from '../components/Post/Card';
import LeftNav from '../components/Nav/LeftNav';

const User = () => {
    const users = useSelector(state => state.rootReducer.usersReducer.users);
    const allPosts = useSelector(state => state.rootReducer.allPostsReducer.allPosts);
    const { id } = useParams();
    const [user, setUser] = useState([]);
    const [userPosts, setUserPosts] = useState([]);

    useEffect(() => {
        users.map((userData) => {
            let renvoyer;
            if(userData._id === id){
                setUser(userData);
                renvoyer = userData;
            }
            return renvoyer;
        })

        if (!isEmpty(allPosts[0])) {
            let posts = [];
            allPosts.map((post) => {
                let renvoyer;
                if(post.posterId === user._id){
                    posts.push(post);
                }
                return renvoyer;
            })
            setUserPosts(posts);
        }

    }, [users, id, allPosts, user]);

    return (
        <div className='user-page'>
            <div className='user-presentation'>
                {user && (
                <>
                <div className='user-container'>
                    <div className='user-left'>
                        <h3>{user.pseudo}</h3>
                        <img src={user.picture} alt="user-pic" />
                    </div>
                    <div className='user-right'>
                        <div className='user-infos column'>
                            <h3>Informations</h3>
                            {user.bio ? <span>Bio : {user.bio} </span> : ''}
                            <span>Email : {user.email ? user.email : ''}</span>
                        </div>
                        <h3>Statistiques</h3>
                        <div className='user-stats'>
                            <div className='left-stats column'>
                                <span>Abonnés : {user.followers ? user.followers.length : 0}</span>
                                <span>Abonnements : {user.following ? user.following.length : 0}</span>
                            </div>
                            <div className='right-stats column'>
                                <span>Nombre de likes : {user.likes ? user.likes.length : 0}</span>
                                <span>Membre depuis le : {dateParser(user.createdAt)}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='user-posts'>
                    <h3>Les posts de {user.pseudo} :</h3>
                    <ul>
                        {!isEmpty(userPosts[0]) &&
                            userPosts.map((post) => {
                                return <Card post={post} key={post._id} />;
                        })}
                    </ul>
                </div>
                </>
                )}
            </div>
            <LeftNav />
        </div>
    );
};

export default User;