import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";

//Slice
export const allPostsSlice = createSlice({
  name: 'allposts',
  initialState: {
    allPosts: []
  },
  reducers: {
    getAllPosts: (state, action) => {
      state.allPosts = action.payload;
    },
    putAllPost: (state, action) => {
      state.allPosts.map((post) => {
        if(post._id === action.payload.postId){
          post.message = action.payload.message;
        }
        return null;
      });
    },
    unsetAllPost : (state, action) => {
      state.allPosts = state.allPosts.filter((post) => post._id !== action.payload);
    },
    setLikeAllPost : (state, action) => {
      state.allPosts.map((post) => {
        if(post._id === action.payload.postId){ 
          post.likers = [...post.likers, action.payload.userId];
        }
        return null;
      });
    },
    unsetLikeAllPost : (state, action) => {
        state.allPosts.map((post) => {
        if(post._id === action.payload.postId){
          post.likers = post.likers.filter((id) => id !== action.payload.userId);
        }
        return null;
      });
    },
    putAllComment : (state, action) => {
      state.allPosts.map((post) => {
        if(post._id === action.payload.postId){
          post.comments.map((comment) => {
            if(comment._id === action.payload.commentId){
              comment.text = action.payload.text;
            }
            return null
          });
        }
        return null;
      });
    },
    unsetAllComment : (state, action) => {
      state.allPosts.map((post) => {
        if(post._id === action.payload.postId){
          post.comments = post.comments.filter((comment) => comment._id !== action.payload.commentId);
        }
        return null;
      });
    }
  }
});

export default allPostsSlice.reducer;

// Actions
const { getAllPosts, putAllPost, unsetAllPost, setLikeAllPost, unsetLikeAllPost, putAllComment, unsetAllComment} = allPostsSlice.actions;

export const fetchAllPosts = () => async dispatch => {
    await axios({
      method: 'get',
      url : process.env.REACT_APP_API_URL + "api/post",
      withCredentials: true,
    })
    .then((res) => {
      dispatch(getAllPosts(res.data))
    })
    .catch((err) => console.log(err));
};
export const updateAllPost = (postId, message) => async dispatch => {
  dispatch(putAllPost({postId, message}))
}; 
export const deleteAllPost = (postId) => async dispatch => {
  dispatch(unsetAllPost(postId))
}; 

export const likeAllPost = (postId, userId) => async dispatch => {
  dispatch(setLikeAllPost({postId, userId}))
}; 

export const unlikeAllPost = (postId, userId) => async dispatch => {
  dispatch(unsetLikeAllPost({postId, userId}))
}; 

export const editAllComment = (postId, commentId, text) => async dispatch => {
  dispatch(putAllComment({postId, commentId, text}))
}; 
export const deleteAllComment = (postId, commentId) => async dispatch => {
  dispatch(unsetAllComment({postId, commentId}))
};

