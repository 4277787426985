import { createSlice } from '@reduxjs/toolkit'

//Slice
export const trendingSlice = createSlice({
  name: 'trending',
  initialState: {
    trending: []
  },
  reducers: {
    getTrending: (state, action) => {
      state.trending = action.payload;
    }
  }
});

export default trendingSlice.reducer;

// Actions
const { getTrending } = trendingSlice.actions;

export const fetchTrending = (sortedArray) => async dispatch => {
    dispatch(getTrending(sortedArray))
};