import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {fetchUserErrors} from './errorsSlice';

//Slice
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: []
  },
  reducers: {
    getUser: (state, action) => {
      state.user = action.payload;
    },
    setPicture: (state, action) => {
      state.user.picture = action.payload;
    },
    setBio: (state, action) => {
      state.user.bio = action.payload;
    },
    setFollow : (state, action) => {
      state.user.following = [...state.user.following, action.payload];
    },
    unsetFollow : (state, action) => {
      state.user.following = state.user.following.filter((id) => id !== action.payload);
    }
  }
});

export default userSlice.reducer;

// Actions
const { getUser, setPicture, setBio, setFollow, unsetFollow} = userSlice.actions;

export const fetchUser = (uid) => async dispatch => {
    await axios({
      method: 'get',
      url : process.env.REACT_APP_API_URL + "api/user/" + uid,
      withCredentials: true,
    })
    .then((res) => dispatch(getUser(res.data)))
    .catch((err) => console.log(err));
};

export const uploadPicture = (data, _id) => async dispatch => {
  await axios
    .post(process.env.REACT_APP_API_URL + "api/user/upload/", data)
    .then((res) => {
        if (res.data.errors){
          dispatch(fetchUserErrors(res.data.errors));
        }else{
          let errors = { format: "", maxSize: ""};
          dispatch(fetchUserErrors(errors));
          axios.get(process.env.REACT_APP_API_URL + 'api/user/' + _id)
          .then((res) => {
              dispatch(setPicture(res.data.picture));
          })
        }
      })
    .catch((err) => console.log(err));
};

export const updateBio = (bio, _id) => async dispatch => {
  await axios
    .put(process.env.REACT_APP_API_URL + "api/user/" + _id, bio)
    .then((res) => {
        dispatch(setBio(res.data.bio));
      })
    .catch((err) => console.log(err));
};

export const followUser = (uid, idToFollow) => async dispatch => {
  await axios({
    method: 'patch',
    url : process.env.REACT_APP_API_URL + "api/user/follow/" + uid,
    data : {idToFollow},
    withCredentials: true,
  })
  .then((res) => dispatch(setFollow(idToFollow)))
  .catch((err) => console.log(err));
};

export const unFollowUser = (uid, idToUnfollow) => async dispatch => {
  await axios({
    method: 'patch',
    url : process.env.REACT_APP_API_URL + "api/user/unfollow/" + uid,
    data : {idToUnfollow},
    withCredentials: true,
  })
  .then((res) => dispatch(unsetFollow(idToUnfollow)))
  .catch((err) => console.log(err));
};