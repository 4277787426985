import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import {fetchPostErrors} from './errorsSlice';

//Slice
export const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    posts: []
  },
  reducers: {
    getPosts: (state, action) => {
      state.posts = action.payload;
    },
    putPost: (state, action) => {
      state.posts.map((post) => {
        if(post._id === action.payload.postId){
          post.message = action.payload.message;
        }
        return null;
      });
    },
    unsetPost : (state, action) => {
      state.posts = state.posts.filter((post) => post._id !== action.payload);
    },
    setLikePost : (state, action) => {
      state.posts.map((post) => {
        if(post._id === action.payload.postId){ 
          post.likers = [...post.likers, action.payload.userId];
        }
        return null;
      });
    },
    unsetLikePost : (state, action) => {
        state.posts.map((post) => {
        if(post._id === action.payload.postId){
          post.likers = post.likers.filter((id) => id !== action.payload.userId);
        }
        return null;
      });
    },
    postComment: (state, action) => {
      state.posts.map((post) => {
        if(post._id === action.payload._id){
          post.comments = action.payload.comments;
        }
        return null;
      });
    },
    putComment : (state, action) => {
      state.posts.map((post) => {
        if(post._id === action.payload.postId){
          post.comments.map((comment) => {
            if(comment._id === action.payload.commentId){
              comment.text = action.payload.text;
            }
            return null
          });
        }
        return null;
      });
    },
    unsetComment : (state, action) => {
      state.posts.map((post) => {
        if(post._id === action.payload.postId){
          post.comments = post.comments.filter((comment) => comment._id !== action.payload.commentId);
        }
        return null;
      });
    }
  }
});

export default postsSlice.reducer;

// Actions
const { getPosts, putPost, postComment, unsetPost, setLikePost, unsetLikePost, putComment, unsetComment } = postsSlice.actions;

export const fetchPosts = (num) => async dispatch => {
    await axios({
      method: 'get',
      url : process.env.REACT_APP_API_URL + "api/post",
      withCredentials: true,
    })
    .then((res) => {
      const array =  res.data.slice(0, num);
      dispatch(getPosts(array))
    })
    .catch((err) => console.log(err));
};

export const updatePost = (postId, message) => async dispatch => {
  await axios({
    method: 'put',
    url : process.env.REACT_APP_API_URL + "api/post/" + postId,
    data : {
      message : message
    },
    withCredentials: true,
  })
  .then((res) => {dispatch(putPost({postId, message}))})
  .catch((err) => console.log(err));
}; 
export const deletePost = (postId) => async dispatch => {
  await axios({
    method: 'delete',
    url : process.env.REACT_APP_API_URL + "api/post/" + postId,
    withCredentials: true,
  })
  .then((res) => {dispatch(unsetPost(postId))})
  .catch((err) => console.log(err));
}; 

export const likePost = (postId, userId) => async dispatch => {
  await axios({
    method: 'patch',
    url : process.env.REACT_APP_API_URL + "api/post/like-post/" + postId,
    data : {
      id : userId
    },
    withCredentials: true,
  })
  .then((res) => {dispatch(setLikePost({postId, userId}))})
  .catch((err) => console.log(err));
}; 

export const unlikePost = (postId, userId) => async dispatch => {
  await axios({
    method: 'patch',
    url : process.env.REACT_APP_API_URL + "api/post/unlike-post/" + postId,
    data : {
      id : userId
    },
    withCredentials: true,
  })
  .then((res) => {dispatch(unsetLikePost({postId, userId}))})
  .catch((err) => console.log(err));
}; 

export const addComment = (postId, commenterId, text, commenterPseudo) => async dispatch => {
  await axios({
    method: 'patch',
    url : process.env.REACT_APP_API_URL + "api/post/comment-post/" + postId,
    data : {
      commenterId : commenterId,
      commenterPseudo : commenterPseudo,
      text : text
    },
    withCredentials: true,
  })
  .then((res) => {dispatch(postComment(res.data))})
  .catch((err) => console.log(err));
}; 
export const editComment = (postId, commentId, text) => async dispatch => {
  await axios({
    method: 'patch',
    url : process.env.REACT_APP_API_URL + "api/post/edit-comment-post/" + postId,
    data : {
      commentId : commentId,
      text : text 
    },
    withCredentials: true,
  })
  .then((res) => {dispatch(putComment({postId, commentId, text}))})
  .catch((err) => console.log(err));
}; 
export const deleteComment = (postId, commentId) => async dispatch => {
  await axios({
    method: 'patch',
    url : process.env.REACT_APP_API_URL + "api/post/delete-comment-post/" + postId,
    data : {
      commentId : commentId
    },
    withCredentials: true,
  })
  .then((res) => {dispatch(unsetComment({postId, commentId}))})
  .catch((err) => console.log(err));
};

export const addPost = (data) => async dispatch => {
  await axios
    .post(process.env.REACT_APP_API_URL + "api/post/", data)
    .then((res) => {
      if (res.data.errors){
        dispatch(fetchPostErrors(res.data.errors));
      }else{
        let errors = { format: "", maxSize: ""};
        dispatch(fetchPostErrors(errors));
      }
    })
    .catch((err) => console.log(err));
};
